import { Fragment } from 'react';

import { Box, Button, Flex, Image, Link, Text } from '@opendoor/bricks/core';

import {
  ExclusivesRoutes,
  ExternalRoutes,
  OpendoorRoutes,
} from 'components/exclusives/constants/routes';

import { useExclusivesTypeContext } from 'helpers/exclusives/ExclusivesTypeContext';

type FooterProps = {
  cta?: React.ReactNode;
};

export const Footer: React.FC<FooterProps> = ({ cta }) => {
  const { type } = useExclusivesTypeContext();
  const analyticsPrefix = `cosmos-${type}-footer`;
  const navigationLinks = footerPrimaryNavigationLinks(type === 'exclusives');

  return (
    <Box as="footer" backgroundColor="warmgrey950" py={64}>
      <Box mx="auto" maxWidth="var(--content-max-width)" px={[32, null, null, 64]} width="100%">
        <Flex alignItems="flex-start" flexDirection="column" gap={[24, null, 32]} pb={64}>
          <Image
            alt="Opendoor Exclusives logo"
            height={32}
            src="https://images.opendoor.com/source/s3/imgdrop-production/2023-08-1691025184151-40904.svg?preset=square-2048"
          />

          <Flex
            flexDirection={['column', null, null, 'row']}
            gap={72}
            justifyContent="space-between"
            width="100%"
          >
            <Flex alignItems="flex-start" flexDirection="column" gap={24}>
              <Text
                color="white0"
                fontSize={['24px', null, '28px']}
                fontWeight="medium"
                letterSpacing="-2.8"
                lineHeight="110"
                maxWidth={440}
              >
                {type === 'exclusive-offers' ? FOOTER_SELLER_TEXT : FOOTER_BUYER_TEXT}
              </Text>
              {cta ? (
                cta
              ) : (
                <Button
                  analyticsName={`${analyticsPrefix}-getting-started`}
                  aria-label="Go to Exclusives"
                  border={0}
                  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                  type="button"
                  width="auto"
                  variant="secondary"
                >
                  Get started
                </Button>
              )}
            </Flex>

            <Flex
              display="grid"
              gap={[32, null, null, 72]}
              gridTemplateColumns={[
                'repeat(2, 1fr)',
                null,
                'repeat(3, 1fr)',
                `repeat(${navigationLinks.length}, auto)`,
              ]}
            >
              {navigationLinks.map((category) => (
                <Flex flexDirection="column" gap={5} key={`footer-category-${category.id}`}>
                  <Text color="white0" fontWeight="medium" size="120">
                    {category.label}
                  </Text>
                  <Flex alignItems="flex-start" flexDirection="column" gap={3}>
                    {category.links.map((link) => (
                      <Link
                        _hover={{ textDecoration: 'underline' }}
                        aria-label={`Go to ${link.label}`}
                        analyticsName={`${analyticsPrefix}-${link.id}`}
                        color="warmgrey700"
                        href={link.href}
                        key={`footer-link-${category.id}-${link.id}`}
                        target="_blank"
                      >
                        <Text color="inherit" size="110">
                          {link.label}
                        </Text>
                      </Link>
                    ))}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>

        <Flex
          borderTop={1}
          borderTopStyle="solid"
          borderTopColor="warmgrey900"
          flexDirection="column"
          gap={7}
          pt={9}
        >
          <Flex flexDirection="column" gap={4}>
            {FOOTER_LEGAL_TEXT.map((footerLegalText, index) => (
              <Text color="warmgrey700" key={`footer-legal-text-${index}`} size="100">
                {footerLegalText}
              </Text>
            ))}
          </Flex>

          <Flex alignItems="center" color="warmgrey700" flexWrap="wrap">
            <Text color="warmgrey700" size="100">
              © {new Date().getFullYear()} Opendoor
            </Text>

            {FOOTER_SECONDARY_LINKS.map((footerSecondaryLink) => (
              <Fragment key={`footer-secondary-link-${footerSecondaryLink.id}`}>
                &nbsp;&middot;&nbsp;
                <Link
                  _hover={{ textDecoration: 'underline' }}
                  aria-label={`Go to ${footerSecondaryLink.label}`}
                  analyticsName={`${analyticsPrefix}-${footerSecondaryLink.id}`}
                  color="warmgrey700"
                  href={footerSecondaryLink.href}
                  target="_blank"
                  textDecoration="underline"
                >
                  <Text color="inherit" size="100">
                    {footerSecondaryLink.label}
                  </Text>
                </Link>
              </Fragment>
            ))}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

const FOOTER_LEGAL_TEXT: string[] = [
  'Opendoor-Owned Exclusives are owned and advertised by Opendoor and its affiliated companies. For these homes, no listing, advertising, or marketing services are provided by, and no commissions are received by, the Opendoor Brokerages as part of the Exclusives program.',
  'Customer-Owned Exclusives, where available, are owned by third party sellers and are advertised by Opendoor Connect, a non-MLS member, licensed brokerage, and its affiliated companies. Opendoor Connect serves sellers only, and does not represent buyers or provide buyer agency services.',
  'In all cases, property information is not provided by a MLS. It is deemed reliable but not guaranteed, is provided "AS IS" without any warranty (express or implied), and should be independently verified.',
];

const FOOTER_BUYER_TEXT = `Self-tour and buy homes before they're listed anywhere else`;
const FOOTER_SELLER_TEXT = `Get a cash offer from Opendoor and we'll help you beat it`;

type FooterLink = {
  href: string;
  id: string;
  label: string;
};

const footerPrimaryNavigationLinks = (isBuyer: boolean) => [
  {
    id: 'product',
    label: 'Product',
    links: [
      {
        id: 'buy',
        label: 'Buy',
        href: ExclusivesRoutes.ROOT,
      },
      {
        id: 'sell',
        label: 'Sell',
        href: ExclusivesRoutes.OFFERS,
      },
    ],
  },
  {
    id: 'about',
    label: 'About',
    links: [
      {
        id: 'company',
        label: 'Company',
        href: OpendoorRoutes.ABOUT,
      },
      {
        id: 'careers',
        label: 'Careers',
        href: OpendoorRoutes.CAREERS,
      },
      {
        id: 'press',
        label: 'Press',
        href: OpendoorRoutes.PRESS,
      },
      {
        id: 'investors',
        label: 'Investors',
        href: ExternalRoutes.INVESTOR,
      },
    ],
  },
  {
    id: 'resources',
    label: 'Resources',
    links: [
      {
        id: 'faq',
        label: 'FAQ',
        href: ExclusivesRoutes.FAQ,
      },
      {
        id: 'blog',
        label: 'Blog',
        href: OpendoorRoutes.BLOG,
      },
      {
        id: 'guides',
        label: 'Guides',
        href: OpendoorRoutes.GUIDES,
      },
      {
        id: 'help-center',
        label: 'Help Center',
        href: ExternalRoutes.HELP_CENTER,
      },
    ],
  },
  {
    id: 'Agents',
    label: 'Agents',
    links: [
      {
        id: 'make-an-offer',
        label: 'Make an offer',
        href: OpendoorRoutes.MAKE_OFFER,
      },
      {
        id: 'request-an-offer',
        label: 'Request an offer',
        href: OpendoorRoutes.AGENTS,
      },
    ],
  },
  {
    id: 'contact',
    label: 'Contact',
    links: [
      {
        id: 'email',
        label: 'Email us',
        href: ExternalRoutes.EMAIL,
      },
      {
        id: 'phone',
        label: isBuyer ? '1-512-706-9938' : '1-888-352-7075',
        href: isBuyer ? ExternalRoutes.CCA_PHONE : ExternalRoutes.CXA_PHONE,
      },
    ],
  },
];

const FOOTER_SECONDARY_LINKS: FooterLink[] = [
  {
    id: 'terms-of-service',
    label: 'Terms of Service',
    href: OpendoorRoutes.TERMS_OF_SERVICE,
  },
  {
    id: 'privacy-policy',
    label: 'Privacy Policy',
    href: OpendoorRoutes.PRIVACY_POLICY,
  },
  {
    id: 'privacy-request',
    label: 'Do Not Sell My Info',
    href: OpendoorRoutes.PRIVACY_REQUEST,
  },
  {
    id: 'faq',
    label: 'FAQ',
    href: ExclusivesRoutes.FAQ,
  },
  {
    id: 'info-about-brokerage-services',
    label: 'Info About Brokerage Services',
    href: ExternalRoutes.BROKERAGE_SERVICES_INFO,
  },
  {
    id: 'consumer-protection-services',
    label: 'Consumer Protection Notice',
    href: ExternalRoutes.CONSUMER_PROTECTION_NOTICE,
  },
];
